// In src/App.js

import React from 'react';
import './App.css';

function App() {
  return (
    <>
      <header className="header">
        <div className="logo">AutoFarm</div>
        <button className="guide-button">Guide</button>
      </header>
      <main className="main-content">
      <h1 className="title">farming airdrop on X is hard<br/> but we made it easy</h1>
        <h3 className="subtitle">Automate Quote retweet, comments <br/>and tweets. faster farming.</h3>
        <a href="https://t.me/Auto_farming_bot" className="try-button" target="_blank" rel="noopener noreferrer">Try bot for Free</a>
      </main>
    </>
  );
}

export default App;
